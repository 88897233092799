.menu{
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     background-color: #ECF0F5;
}

.menuTop{
     height: 40%;
     width: 100%;
     margin-top: 20px;
     margin-bottom: 20px;
     display: flex;
     align-items: center;
     padding: 0px 10px;
}

.profilePic{
     font-size: 10rem;
     fill: var(--evercare-darkblue);
}
.bigAvatar{
     width: 200px;
     height: 200px;
     object-fit: cover;
     object-position: center;
     border-radius: 50%;
}

.userGenInfo,.userMedInfo{
     width: 40%;
     height: 100%;
     margin-left: 15px;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 10px;
}
.info{
     width: 100%;
     text-align: left;
     font-weight: 600;
     color: var(--evercare-darkblue);
}

.userMedInfo{
     margin-left: 10px;
}

.userVals{
     color: black;
     font-weight: normal;
}

.menuBottom{
     width: 100%;
     height: 60%;
     display: flex;
     gap: 20px;
     flex-wrap: wrap;
     padding: 20px 10px 0px 20px;
}


@media screen and (max-width:1200px){
    .bigAvatar{
     width: 150px;
     height: 150px;
    }
}

@media screen and (max-width:750px){
    .menu{
     width: 100%;
    }
}

@media screen and (max-width:600px){
    .bigAvatar{
     width: 100px;
     height: 100px;
    }

    .info,.userVals{
     font-size: 0.8rem;
    }
}

@media screen and (max-width:560px){
     .bigAvatar{
          display: none;
     }
    .menuTop{
     display: none;
    }
}