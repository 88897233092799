.contact{
     display: flex;
     flex-direction: column;
     padding: 10px;
     width: 100%;
     height: 100%;
}

.contactWrapper{
     width: 100%;
     height: fit-content;
     display: flex;
     flex-direction: column;
     gap: 10px;
     margin-top: 20px;
     background-color: #fff;
     padding: 10px 20px;
}

.required{
     color:red;
     font-weight: 600;
}

.submitContact{
     width: 200px;
     height:40px;
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 5px;
     background-color: var(--evercare-darkblue);
     color: white;
     font-weight: 600;
     margin-left: 30%;
}

@media screen and (max-width:500px){
     .submitContact{
          width: 80%;
          margin-left: 10%;
     }
}