.titlebar{
     width: 100%;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     position: relative;
     z-index: 777;
}

.svgWrapper{
     width: 50px;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     
     color: white;
}

.svgWrapper >svg{
     font-size: 2rem;
}

.txtWrapper{
     display: flex;
     width: calc(100% - 50px);
     height: 100%;
     background-color: #fff;
     padding-left: 10px;
     align-items: center;
     justify-content: flex-start;
     font-size: 1.2rem;
     font-weight: 700;
     color:black;
     
}