.faq{
     display: flex;
     width: 100%;
     height: 100%;
     padding: 10px;
     flex-direction: column;
}

.faqItem{
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: flex-start;
     background-color: #fff;
     position: relative;
     transition: .5s ease-out;
}
.expandIcon{
     font-size: 1rem;
     font-weight: 600;
     color: #3C8DBC;
     margin: 10px;
}
.faqItemQuestion{
     width: 100%;
     height: 40px;
     border-top:1px solid #F4F4F4;
     border-bottom:1px solid #F4F4F4;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     font-size: 1.2rem;
     cursor: pointer;
     z-index: 666;
     background-color: #fff;
}
.faqItemAnswer{
     display: flex;
     background-color: #fff;
     width: 100%;
     padding: 0px 40px;
     align-items: center;
     justify-content: flex-start;
     font-size: 0.9rem;
     transition: .5s ease-out;
     position: absolute;
     left: 0px;
     z-index: 555;
}

.vanish{
     display: none;
}


@media screen and (max-width:500px){
     .faqItemAnswer{
          height: 100px;
          font-size: 0.8rem;
     }
}