.appointment{
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     padding: 10px;
}

.appntWrapper{
     width: 100%;
     height: fit-content;
     display: flex;
     flex-direction: column;
     margin-top: 20px;
}

.appntTabs{
     width: 100%;
     height: 40px;
     display: flex;
     justify-content: flex-start;
     gap: 2px;
}

.appntTab{
     padding: 5px;
     align-items: center;
     justify-content: center;
     display: flex;
     color: #88B4DE;
     cursor: pointer;
}

.activeAppntTab{
     padding: 5px;
     border: 1px solid gray;
     background-color: #D3D3D3;
     color:black;
     align-items: center;
     justify-content: center;
     display: flex;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
     border-bottom: none;
}

.appntNotice{
     width: 100%;
     height: 40px;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     background-color: #D3D3D3;
     font-weight: 600;
     padding-left: 5px;

}

.appntForm{
     width: 100%;
     height: fit-content;
     display: flex;
     flex-direction: column;
     padding-top: 20px;
}

.inputHolder{
     width: 60%;
     height: 30px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-bottom: 10px;
}

.inputHolder>select{
     width: 70%;
     height: 100%;
}

.inputHolder>input{
     width: 70%;
     height: 100%;
}

.inputHolder>select:focus{
     outline: none;
}

.inputHolder>input:focus{
     outline: none;
}

.tandc{
     display: flex;
     height: 30px;
     align-items: center;
     width: 100%;
     justify-content: flex-start;
     gap: 10px;
}
.tandc>label{
     font-size: 0.9rem;
     font-weight: 600;
}

.tandc>input{
     width: 20px;
     height: 20px;
}

.formActions{
     width: 100%;
     height: 40px;
     margin-top: 10px;
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 10px;
}

.makeAppnt, .resetAppnt{
     width: 20%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     font-weight: 600;
     cursor: pointer;
     border-radius: 5px;
}

.makeAppnt{
     background-color: var(--evercare-darkblue);
     color: white;
}

.resetAppnt{
     background-color: #DD4B39;
     color:white
}

@media screen and (max-width:750px){
     .makeAppnt,.resetAppnt{
          width: 40%;
     }
}

@media screen and (max-width:600px){
     .inputHolder{
          height: 70px;
          flex-direction: column;
          width: 100%;
     }
     .inputHolder>label{
          width: 100%;
          text-align-last: left;
          display: flex;
     }
     .inputHolder>select, .inputHolder>input{
          height: 50%;
          width: 100%;
     }

     .inputHolder>textarea{
          width: 100%;
          height: 50%;
     }

     .tandc{
          width: 100%;
          display: flex;
          flex-direction: row;
     }

     .tandc>input{
          width: 15px;
          height: 15px;
     }
}