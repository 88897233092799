.main{
    width: 100%;
    height: 100vh;
    background-image: url("/src/assets/evercare1.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.accessNode{
    width: 25%;
    height: 80vh;
    background: white;
    display: flex;
    display: flex;
    flex-direction: column;
}

.logoArea{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.longLogo{
    width: 100%;
    height: 60%;
    object-fit: contain;
}

.accessArea{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%; 
}

.accessOptions{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.aOpt{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
}

.aOptActive{
    background-color: #E8F0FE;
}

.accessForms{
    background-color: #E8F0FE;
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
}

.aFormTitle{
    width: 100%;
    height: 12%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
}
.error{
    width: 100%;
    height: 10px;
    font-size: 0.8rem;
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.bold{
    font-size: 0.8rem;
    color: var(--text-grey);
}

.aForm{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    gap: 20px;
}

.aFormSelect{
    width: 100%;
    height: 40px;
    border: 1px solid var(--lightGrey);
    font-size: 0.9rem;
    color: var(--text-grey);
    padding-left: 10px;
}

.aFormSelect:focus{
    outline: none;
}

.inputWrapper{
    width: 100%;
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
}

.aFormInput{
    width: 100%;
    height: 100%;
    border: 1px solid var(--lightGrey);
    font-size: 0.9rem;
    color: var(--text-grey);
    padding-left: 10px;
}

.aFormInput:focus{
    outline: none;
}

.inputIcon{
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: transparent;
    height: 100%;
    width: 5%;
    display: flex;
    align-items: center;
    color: var(--text-grey);
    font-size: 1.2rem;
    margin-right: 10px;
    cursor: pointer;
}

.bold>span{
    font-weight: 600;
    font-size: 0.8rem;
}

.aFormBtns{
    width: 100%;
    height: 50px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.loginBtn, .resetBtn{
    width: 50%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

.loginBtn{
    background-color: #428BCA;
}

.resetBtn{
    background-color: #DD4B39;
}

.miscBtns{
    width: 100%;
    font-size: 0.8rem;
    color: var(--text-grey);
    cursor: pointer;
}

.miscBtns:hover{
    color: black;
}

.nodeFooter{
    width: 100%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
    color: var(--text-grey);
}



@media screen and (max-width:1500px) {
    .accessNode{
        width: 30%;
    }
}

@media screen and (max-width:1200px) {
    .accessNode{
        width: 35%;
    }
    
}
@media screen and (max-width:900px) {
    .accessNode{
        width: 50%;
    }
}
@media screen and (max-width:600px) {
    .accessNode{
        width: 60%;
    }
}

@media screen and (max-width:500px) {
    .accessNode{
        width: 90%;
    }
}



@media screen and (max-width:400px) {
    .accessNode{
        width: 100%;
        height: 100vh;

    }
}

