.portal{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/src/assets/evercarebg.svg');
    background-size: cover;

}

.portalNode{
    width: 90%;
    height: 90vh;
    background-color:#E8F0FE;
    display: flex;
    position: relative;
    overflow-y: scroll;
}

.portalNode::-webkit-scrollbar{
    width: 10px;
}
.portalNode::-webkit-scrollbar-thumb{
    background-color: #D3D3D3;
}

.topbar{
    width: 90%;
    height: 10%;
    background-color: var(--evercare-darkblue);
    color: white;
    position: fixed; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    z-index: 999;

}

.tLeft,.tRight{
    display: flex;
    align-items: center;

}

.ppText{
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 100px;
}
.tLeft{
    width: 65%;
    height: 100%;
    display: flex;

}

.evercare{
    margin-right: 20px;
}
.tRight{
    width: 30%;
    height: 100%;
    padding: 0px 20px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    position: relative;
}

.logoutIcon{
    font-size: 1.3rem;
    color: white;
}

.tWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 100%;
    cursor: pointer;
}
.homeWrapper>svg{
    color: white;
}
.userWrapper{
    width: 70%;
}

.userIcon{
    font-size: 2rem;
}
.userAvatar{
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.home{
    font-size: 0.8rem;
}

.user{
    font-size: 0.8rem;
}

.userPopUp{
    width: 300px;
    height: 200px;
    position: absolute;
    top: 100%;
    right:-20px;
    border: 1px solid white;
    background-color: var(--evercare-darkblue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    z-index: 999;
    
}

.popUpIcon{
    margin-top: 10px;
    font-size: 7rem;
    margin-bottom: 15px;
}
.popUpImg{
    margin-top: 10px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.userName{
    font-size: 1.3rem;
}
.userAge{
    font-size: 0.8rem;
}

.menuIconWrapper{
    margin-right: 20px;
    height: 100%;
    width: 10%;
    display: none;
    align-items: center;
    justify-content: center;
}

.menuIcon{
    font-size: 2rem;
    cursor: pointer;
}









.navbar{
    width: 20%;
    height: calc(100vh - 10% + 30px);
    background-color: #222D32;
    position: absolute;
    top: 10%;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.mainArea{
    width: 80%;
    height: fit-content;
    position: absolute;
    left:20%;
    top:10%;
    background-color: #ECF0F5;
}



@media screen and (max-width:1500px){
    
}
@media screen and (max-width:1200px){
    
    .tRight{
        width: 45%;
    }
}

@media screen and (max-width:1100px){
    .portal{
        height:100vh;
    }
    .portalNode{
        height: 100vh;
        width: 100%;
    }
    .topbar{
        width: 100%;
    }
    .navbar{
        height: 100%;
    }
    
}
@media screen and (max-width:900px){
    .topbar{
        width: 100%;
    }
    .tLeft{
        width: 50%;
    }
    .tRight{
        width: 50%;
    }
    .ppText{
        margin-right: 20px;
    }

    .userWrapper{
        width: 100%;
    }
}
@media screen and (max-width:855px){
    .navbar{
        display: none;
    }
    .mainArea{
        width: 100%;
        left: 0px;
    }
    .ppText{
        font-size: 1rem;
    }
    .evercare{
        font-size: 0.8rem;
    }
    .menuIconWrapper{
        display: flex;
    }

}
@media screen and (max-width:690px){
    .tLeft{
        width: 35%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .ppText,.evercare{
        display: flex;
        height: 50%;
        width: 100%;
        align-items: center;
        text-align: left;
    }
    .tRight{
        width: 60%;
    }
}
@media screen and (max-width:600px){
    .topbar{
        padding-right: 0px;
        display: flex;
        justify-content: space-between;

    }
    .tLeft{
        width: 30%;
    }
    .ppText{
        font-size: 0.8rem;
    }
    .evercare{
        font-size: 0.6rem;
    }
    .tRight{
        padding: 0px;
        margin: 0;
        width: 70%;
    }
    .homeWrapper{
        display: none;
    }
}
@media screen and (max-width:560px){
    .portalNode{
        height: 100%;
    }
    .tLeft{
        width: 35%;
    }
    .portalNode::-webkit-scrollbar{
        display: none;
    }
}
@media screen and (max-width:500px){
    
}
@media screen and (max-width:400px){
    
}