.change{
     display: flex;
     flex-direction: column;
     width: 100%;
     height: 100%;
     padding: 10px;

}

.changeForm{
     margin-top: 20px;
     background: #fff;
     display: flex;
     flex-direction: column;
     width: 100%;
     height: fit-content;
     padding: 10px 0px;
}

.warning{
     width: 100%;
     color: red;
     font-size: 0.8rem;
     padding-left: 20%;
     display: flex;
     align-items: center;
}

.changeInput{
     width: 75%;
}




@media screen and (max-width:500px) {

     .changeForm{
          padding: 10px 20px;
     }
     .warning{
          padding-left: 0%;
          justify-content: center;
     }
}



.process{
     margin-top: 10px;
     margin-bottom: 10px;
     width: 100%;
     font-size: 0.9rem;
     color: green;
     font-weight:600;
     display: flex;
     align-items: center;
     justify-content: center;
}