.loader{
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0px;
     left: 0px;
     background-color: rgba(255,255,255,0.9);
     z-index: 999;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     gap: 20px;

}

.ring{
     width: 100px;
     height: 100px;
     border: 5px solid var(--evercare-darkblue);
     border-top: 5px solid lightgray;
     border-radius: 50%;
     animation: spin 1s infinite;
}

.loadingText{
     color: var(--evercare-darkblue);
     font-weight: 600;
     font-size: 1.2rem;
}

@keyframes spin {
     0%{
          transform: rotate(0deg);
     }
     100%{
          transform: rotate(360deg);
     }
}