.feedback{
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     padding: 10px;
}

.lang{
     display: flex;
     width: 30%;
     height: 50%;
     position: absolute;
     right: 5px;
     gap: 10px;
     align-items: center;
}

.pleaseRate{
     width: 100%;
     height: 40px;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     background-color: #fff;
     margin-top: 20px;
     border-top: 3px solid lightgrey;
     padding-left: 10px;
     border-top-left-radius: 2px;
     border-top-right-radius: 2px;
}

.lang>label{
     font-size: 0.9rem;
}
.lang>select{
     font-size: 0.9rem;
}

.lang>select:focus{
     outline: none;
}

.ratesHeader{
     width: 100%;
     height: 40px;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     background-color: #fff;
     border-top: 1px solid lightgrey;
}

.ratesSpan{
     display: flex;
     align-items: center;
     justify-content: center;
     height: 100%;
     font-size: 0.9rem;
     font-weight: 600;
}

.rateNum{
     width: 5%;
}

.question{
     width: 27%;
     max-width: 27%;
}

.excellent,.good,.need,.poor{
     width: 12%;
     justify-content: flex-start;
}

.remarks{
     width: 20%;
     display: flex;
     justify-content: flex-start;
}

.questionArea{
     height: 40px;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     font-weight: 600;
}

.questionArea>span{
     width: 5%;
     font-weight: 600;
     justify-content: center;
     display: flex;
     align-items: center;
}

.q1{
     width: 100%;
     height: 40px;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding-left: 5%;
     background-color: #fff;
     border-bottom: 1px solid lightgray;
}

.q1>span{
     width: 100%;
     font-size: 0.9rem;
}

.smileys{
     width: 48%;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     margin-right: 20px;
}

.smileys>.emojiWrapper{
     width:25%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
}

.emoji{
     font-size: 2rem;
}

.activeEmoji{
     color:orange;
}

.remarksInput{
     width: 20%;
}

.submitFeedback{
     width: 20%;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: var(--evercare-darkblue);
     color: white;
     font-weight: 600;
     margin-left: 40%;
     margin-top: 20px;
     margin-bottom: 20px;
}




@media screen and (max-width:650px) {
     .ratesSpan{
          font-size: 0.9rem;
     }
     .submitFeedback{
          width: 50%;
          margin-left: 25%;
     }
}

@media screen and (max-width:500px) {
     .ratesHeader{
          display: none;
     }

     .questionWrapper{
          flex-direction: column;
     }

     .q1{
          display: flex;
          flex-direction: column;
          height: 80px;
          width: 100%;
          justify-content: flex-start;
     }

     .q1>span{
          height: 40px;
          min-width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 10px;
     }

    
     .smileys{
          width: 100%;
     }

     .lang{
          width: 50%;
     }

     .remarksInput{
          display: none;
     }

     .submitFeedback{
          width: 80%;
          margin-left: 10%;
     }
}

