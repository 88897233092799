.register{
     width: 100%;
     height: 100vh;
     background-image: url("../../assets/evercarebg2.jpg");
     background-repeat: no-repeat;
     background-size: cover;
     background-position: center;
     display: flex;
     align-items: center;
     justify-content: center;
}

.registerModal{
     width: 90%;
     height: 95%;
     background-color: white;
     display: flex;
     flex-direction: column;
}

.rModalTop{
     width: 100%;
     height: 100px;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding-left: 5%;
     
}

.rLogo{
     width: 20%;
     height: 30%;
     margin-right: 5%;
}

.rModalTitle{
     width: 40%;
     font-size: 2rem;
     font-weight: 600;
     color: var(--evercare-darkblue);
     display: flex;
     align-items: center;
     justify-content: center;
}

.miscItems{
     width: 30%;
     height: 100%;
     display: flex;
     justify-content: flex-end;
     gap: 20px;
     color:var(--evercare-darkblue);
     align-items: center;
}

.miscItem{
     cursor: pointer;
}

.rModalMain{
     width: 100%;
     height: 90% ;
     display: flex;
     flex-direction: column;
     justify-content: flex-start;
     padding: 0px 10px;
}

.rModalTabs{
     width: 100%;
     height: 40px;
     display: flex;
     justify-content: flex-start;
}

.rModalTab{
     width: 20%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
     cursor: pointer;
}

.activeRTab{
     background-color: #E8F0FE;
}

.formTitle{
     width: 100%;
     height: 40px;
     font-weight: 600;
     display: flex;
     align-items: center;
     justify-content: center;
     margin-bottom: 20px;
}

.rForm{
     width: 100%;
     height: 70%;
     background-color:#E8F0FE ;
     padding: 10px;
     overflow-y: scroll;
     margin-bottom: 10px;
}

.rForm::-webkit-scrollbar{
     width: 10px;
 }
 .rForm::-webkit-scrollbar-thumb{
     background-color: #D3D3D3;
 }

 .rFormSubmit{
     width: 100%;
     height: 10%;
     margin-top: 5px;
     display: flex;
     align-items: center;
     justify-content: center;
     gap: 20px;
 }

 .rFormBtn{
     width: 15%;
     height: 80%;
     font-weight: 600;
     border-radius: 5px;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
 }

 .rSubmit{
     color:white;
     background-color: #428BCA;
 }

 .rReset{
     color: white;
     background-color:#DD4B39;
 }

 .notice{
     width: 100%;
     background-color: white;
     padding: 10px 0px;
 }

 .tos{
     display: flex;
     padding: 10px;
     text-align: justify;
     flex-direction: column;
 }

 .agree{
     margin-top: 20px;
     display: flex;
     align-items: center;
     justify-content: center;
     font-size: 0.8rem;
     font-weight: 600;
     color: var(--evercare-darkblue);
     width: 100%;
     height: 40px;
 }

 .agreeTxt{
     font-size: 0.8rem;
     margin-left: 10px;
 }

 .return{
     width: 20%;
     margin-left: 40%;
     height: 50px;
     display: flex;
     align-items: center;
     justify-content: center;
     background-color: var(--evercare-darkblue);
     color: white;
     font-weight: 600;
     border-radius: 5px;
     margin-top: 20px;
     cursor: pointer;

 }





 @media screen and (max-width:1200px) {
    .rModalTitle{
     font-size: 1.5rem;
    } 
 }
 @media screen and (max-width:1080px) {
     
}
@media screen and (max-width:900px) {
     .registerModal{
          padding-top: 10px;
          height: 100%;
     }
     .rModalTop{
          flex-direction: column;
     }
     .rLogo{
          width: 35%;
          height: 40%;
          margin-right:none;
     }
     .rModalTitle{
          font-size: 1.2rem;
          height: 40%;
          width: 100%;
     }

     .miscItems{
          height: 20%;
          width: 100%;
          font-size: 0.9rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 5%;
     }

     .miscItem{
          font-size: 0.9rem;
     }

     .rFormBtn{
          width: 20%;
     }

     .return{
          width: 40%;
          margin-left: 30%;
     }

}

@media screen and (max-width:700px) {
     .rModalTop{
          height: 120px;
     }
     .rLogo{
          width: 40%;
     }
     .miscItems{
          justify-content: space-between;
          margin-bottom: 20px;
     }
     .rModalTab{
          width: 25%;
     }
}
@media screen and (max-width:600px) {
     .registerModal{
          width: 100%;
          height: 100%;
     }

     .rFormBtn{
          width: 30%;
     }
     .agreeTxt{
          font-size: 0.8rem;
          margin-top:10px;
          margin-left: 10px;
      }
}

@media screen and (max-width:500px) {
     .rModalTab{
          width: 33.3%;
          font-size: 0.9rem;
     }
     .rLogo{
          width: 60%;
     }
     .rFormSubmit{
          position: absolute;
          bottom: 0px;
     }
     .notice{
          position: absolute;
          bottom: 10%;
     }
     .return{
          width: 80%;
          margin-left: 10%;
     }
}


@media screen and (max-width:400px) {

     
     .rForm::-webkit-scrollbar{
          width: 5px;
     }
}