@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
}

a{
  color: inherit;
  text-decoration: none;
  font-size: inherit;
}
:root{
  --evercare-darkblue:#002355;
  --evercare-lightblue:#64C7E8;
  --text-grey:#7F6666;
  --lightGrey:#D2D6DE;
}

.App{
  width: 100%;
  height: 100%;
}
