.menuTabs{
     display: flex;
     height: 100px;
     width: 23%;
     background-color: #fff;
     cursor: pointer;
}

.tabIconWrapper{
     width: 45%;
     height: 100%;
     display: flex;
     align-items: center;
     justify-content: center;
}

.texts{
     width: 55%;
     height: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     padding-top: 10%;
     padding-left: 10px;
}

.textA{
     width: 100%;
     height: 30%;
     text-transform: uppercase;
     align-items: center;
     margin-bottom: 10px;
     font-size: 0.9rem;
}

.textB{
     width: 100%;
     height: 30%;
     align-items: center;
     font-weight: 700;
}

.tabIconWrapper>svg{
     font-size: 3rem;
     color: white;
}

@media screen and (max-width:1100px){
    .menuTabs{
     width: 30%;
    }
}

@media screen and (max-width:950px){
    .menuTabs{
     width: 45%;
    }
}

@media screen and (max-width:560px){
    .menuTabs{
     width: 100%;
    }
}